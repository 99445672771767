import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import { DiagnosisResultPersonalityType } from '@/gql/graphql';
import { digest } from 'ohash';

export const useUserNicknameForDisplay = (
  nickname: MaybeRef<string | null | undefined>
) => {
  return computed(() => {
    const nicknameStr = unref(nickname);
    const fallbackedNickname = !nicknameStr ? 'ゲスト' : nicknameStr;
    return `${fallbackedNickname}さん`;
  });
};

const UseAnonymizedUserThumbnailAnonymizedUserAttributesFragment = graphql(`
  fragment UseAnonymizedUserThumbnailAnonymizedUserAttributes on AnonymizedUserAttributes {
    id
  }
`);
type PersonalityIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
const PersonalityMap: Record<PersonalityIndex, DiagnosisResultPersonalityType> =
  {
    1: DiagnosisResultPersonalityType.A,
    2: DiagnosisResultPersonalityType.B,
    3: DiagnosisResultPersonalityType.C,
    4: DiagnosisResultPersonalityType.D,
    5: DiagnosisResultPersonalityType.E,
    6: DiagnosisResultPersonalityType.F,
    7: DiagnosisResultPersonalityType.G,
    8: DiagnosisResultPersonalityType.H,
  };
export const useAnonymizedUserThumbnail = (
  anonymizedUser: RefOrGetterFunction<
    FragmentType<
      typeof UseAnonymizedUserThumbnailAnonymizedUserAttributesFragment
    >
  >
): ComputedRef<DiagnosisResultPersonalityType> => {
  const anonymizedUserFragment = useComputedFragment(
    UseAnonymizedUserThumbnailAnonymizedUserAttributesFragment,
    anonymizedUser
  );

  return computed(() => {
    // ハッシュ化したIDの最初の文字のコードポイントを取得し、8で割った余りに1を加えて
    // 1〜8の範囲の数値を生成する
    const hash = digest(anonymizedUserFragment.value.id);
    const firstChar = hash.charCodeAt(0);
    const personalityIndex = ((firstChar % 8) + 1) as PersonalityIndex;
    return PersonalityMap[personalityIndex];
  });
};
